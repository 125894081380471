import React from "react";
import { Component } from "react";
import { Row, Col, Container, OverlayTrigger, Tooltip } from "react-bootstrap"
import ButtonLink from "./buttonLink";
import AutoIcon from "./AutoIcon";
import Fade from "./transition/Fade";
import ReactMarkdown from 'react-markdown'

class Timeline extends Component {

    vertical(sections) {
        return (
            <Container className="mt-2 pt-1 py-5">
                <Row>
                    <Col lg={7} className="mx-auto" >
                        <ul className="timeline">
                            {sections.map((item, index) => {
                                return (
                                    <li className="timeline-item rounded ml-4 p-4 shadow" key={'ID' + item.id}>
                                        <Fade>
                                            <div className="timeline-arrow"></div>
                                            <h2 className="h5 mb-0 text-capitalize">
                                                <AutoIcon text={item.title} />
                                                {item.title}
                                            </h2>
                                            <span className="small">{item.altImage}</span>
                                            <ReactMarkdown className="text-small mt-2 font-weight-light">{item.body}</ReactMarkdown>
                                            <span className="small text-center">
                                                {item.link && item.linkLabel ? <ButtonLink href={item.link} label={item.linkLabel} 
                                                    title={item.title} 
                                                    ></ButtonLink> : ''}
                                            </span>
                                        </Fade>
                                    </li>
                                )
                            })}
                        </ul>
                    </Col>
                </Row>
            </Container>
        )
    }
    renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Simple tooltip
        </Tooltip>
    );
    oriz(sections) {
        return (
            <Container className="mt-2 pt-1 py-5">
                <Row>
                    <Col className="mx-auto" >
                        <div className="timeline-steps">
                            {sections.map((item, index) => {
                                return (
                                    <div className="timeline-step" key={'ID_' + item.id}>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={'tooltip_' + item.id}>
                                                {item.body ? item.body.replace(/(&nbsp;|<([^>]+)>)/ig, "") : item.title }
                                            </Tooltip>}
                                        >
                                            <Fade>
                                                <div className="timeline-content">
                                                    <div className="inner-circle"></div>
                                                    <p className="h6 mt-3 mb-1">{item.altImage}</p>
                                                    <p className="h6 text-muted mb-0 mb-lg-0"><AutoIcon text={item.title} />  {item.title}</p>
                                                </div>
                                            </Fade>
                                        </OverlayTrigger>
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const { sections } = this.props
        let { vertical } = this.props
        if (vertical !== false) {
            vertical = true;
        }
        if (sections) {
            if (vertical) {
                return this.vertical(sections);
            } else {
                return this.oriz(sections);
            }
        }
        else {
            return (
                <Container className="mt-2 pt-1"></Container>
            )
        }
    }
}
export default Timeline