import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBirthdayCake, faCalendarDay, faMedal, faMicrophone, faPalette, faPhotoVideo, faWrench } from "@fortawesome/free-solid-svg-icons";


class AutoIcon extends Component {

    reMostra = /mostra/i;
    reBirthday = /nascita/i;
    reVideo = /video|videomaker|fotografo|fotografia|ritratt/i;
    rePresentatore = /presentatore|speaker|microphone|audio/i;
    reConcorso = /concorso|gara|premio|challenge/i;
    reProject = /progetto|idea|project/i;
    reCalendar = /data|eventi|evento/i;

    icon(text) {
        let faIcon = null;
        if (text) {
            if (this.reMostra.exec(text)) {
                faIcon = faPalette
            } else if (this.reVideo.exec(text)) {
                faIcon = faPhotoVideo;
            } else if (this.rePresentatore.exec(text)) {
                faIcon = faMicrophone
            } else if (this.reConcorso.exec(text)) {
                faIcon = faMedal
            } else if (this.reProject.exec(text)) {
                faIcon = faWrench
            } else if (this.reBirthday.exec(text)) {
                faIcon = faBirthdayCake
            } else if (this.reCalendar.exec(text)) {
                faIcon = faCalendarDay
            }
        }
        return faIcon ? <FontAwesomeIcon icon={faIcon} className="mr-1" /> : ''
    }

    render() {
        const {text} = this.props || '';
        return this.icon(text);
    }

}

export default AutoIcon